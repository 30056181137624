import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
    @Input() isMalaysia = false;
    @Input() PortalMessageList = {};

    ngOnInit() {
        this.isMalaysia = this.isMalaysia;
        this.PortalMessageList = this.PortalMessageList;
    }
}

