import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PricechangeService } from '../shared/services/pricechange.service';
@Component({
  selector: 'app-subs',
  templateUrl: './subs.component.html',
  styleUrls: ['../subs/subs.component.css']
})
export class SubsComponent implements OnInit {
  private user: any = JSON.parse(sessionStorage.getItem("user"));
  public userEmailAddress: any;
  dropdownList = [];
  dropdownSettings = {};
  heading = "Subs";
  restaurantNameList = [];
  restuarantNameProductMap = {};
  categories = [];
  products = [];
  hidetable = false;
  restaurantselected = false;
  restaurantName = "";
  checkedRowsOnCatSelection = [];
  SelectedCategory = "";
  userUpdateModel = {};
  multiItemSelected = false;
  PortalMessageList = [];
    isSingapore = false;
    isAustralia = false;
    isMalaysia = false;
  hideLoader = false;
    isNZ = false;
  errorMessage = false;
  editdate = new Date();
  EditPricingInUI = false;

  constructor(private pricechangeService: PricechangeService) { }

  ngOnInit() {
    this.heading = "Subs";
    this.getAllRestaurantProducts();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll: false
    };
  }
  onItemSelect(item: any) {
    for (const [key, value] of Object.entries(this.restuarantNameProductMap)) {
      if (value["RestaurantId"] === item["item_id"]) {
        for (let product of value["Product"]) {
          product["MultiItemSelected"] = true;
        }
      }
    }
  }
  OnItemDeSelect(item: any) {
    for (const [key, value] of Object.entries(this.restuarantNameProductMap)) {
      if (value["RestaurantId"] === item["item_id"]) {
        for (let product of value["Product"]) {
          product["MultiItemSelected"] = false;
        }
      }
    }
  }
  
  public getAllRestaurantProducts() {
    this.pricechangeService.getSubDetails().subscribe(
      data => {
        this.restuarantNameProductMap = data["RestaurantProductModel"].map(({ RestaurantName, RestaurantId, Product, IsRegistered, Country, EditPricingInUI, ControlPricingInUIEditDate }) => ({ RestaurantName, RestaurantId, Product, IsRegistered, Country, EditPricingInUI, ControlPricingInUIEditDate }));
        (<Array<String>>this.restuarantNameProductMap).forEach((item, index) => {
          if (item["IsRegistered"]) {
            this.dropdownList.push({
              item_id: item["RestaurantId"],
              item_text: item["RestaurantName"]
            })
          }
          if (data["EmailAddress"] != null) {
            this.userEmailAddress = data["EmailAddress"];
          }
        });
        (<Array<String>>this.restuarantNameProductMap).forEach((item, index) => {
          this.restaurantNameList.push({
            RestaurantName: item["RestaurantName"],
            IsRegistered: item["IsRegistered"]
          })
        });
        for (const [key, value] of Object.entries(data["RestaurantProductModel"])) {
            if (value["Country"] === "NZ")
                this.isNZ = true;
            if (value["Country"] === "SG")
                this.isSingapore = true;
            if (value["Country"] === "AU")
                this.isAustralia = true;
            if (value["Country"] === "MY")
                this.isMalaysia = true;
          if (value["PricingPortalMessage"] != null) {
            this.PortalMessageList = value["PricingPortalMessage"].split(",,");
          }
        };
        this.hideLoader = true;
      },
      err => {
        if (err.status === 401) this.errorMessage = true;
      },
    );
  }

  public addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  public onChangeOfRestaurantList(restaurantName) {
    this.restaurantName = restaurantName;
    this.restaurantselected = true;
    for (const [key, value] of Object.entries(this.restuarantNameProductMap)) {
      if (restaurantName.indexOf(value["RestaurantName"]) > -1) {
        this.products = value["Product"];
          if (value["Country"] === "MY") {
              this.editdate = new Date(value["ControlPricingInUIEditDate"]);
              this.EditPricingInUI = value["EditPricingInUI"];
          }
      }
    }
    //For MY, the edit access is given only for 14 days
    if (this.isMalaysia) {
        let allowedDaystoEdit = 14;
        if ((new Date() > this.addDays(this.editdate, allowedDaystoEdit)) === true) {
            this.EditPricingInUI = false;
        } else
            this.EditPricingInUI = true;
        this.restaurantselected = (this.EditPricingInUI === true) ? true : false;
    }
    for (let product of this.products) {
      this.categories.push(product["CategoryName"]);
    }
    this.categories = this.categories.reduce(function (a, b) {
      if (a.indexOf(b) < 0) a.push(b);
      return a;
    }, []);
  }

  submitPriceChangeDetails() {
    let UserUpdateInfoModelArray = [];
    for (const [key, value] of Object.entries(this.restuarantNameProductMap)) {
        for (let prod of value["Product"]) {
          let updateProduct = {};
          if (prod["IsSixInchPriceChanged"]) {
            updateProduct["SixInchProductPrice"] = prod["SixInchPrice"];
            updateProduct["SixInchProductSizeSysId"] = prod["SixInchSizeId"];
          }
          if (prod["IsFootLongPriceChanged"]) {
            updateProduct["FootLongProductPrice"] = prod["FootLongPrice"];
            updateProduct["FootLongProductSizeSysId"] = prod["FootLongSizeId"];
          }
          if (prod["IsStandardPriceChanged"]) {
            updateProduct["StandardProductPrice"] = prod["StandardPrice"];
            updateProduct["StandardProductSizeSysId"] = prod["StandardSizeId"];
          }
          updateProduct["productActive"] = "active";
          updateProduct["RestaurantSysId"] = value["RestaurantId"];
          updateProduct["UpdatedBy"] = this.userEmailAddress;
          if (this.restaurantName.indexOf(value["RestaurantName"]) > -1) {
            updateProduct["MultiItemSelected"] = true;
          } else {
            updateProduct["MultiItemSelected"] = prod["MultiItemSelected"];
          }
          if (!(prod["IsPriceChanged"] === undefined)) {
            UserUpdateInfoModelArray[UserUpdateInfoModelArray.length] = (updateProduct);
          }
        }
      this.userUpdateModel["UseUpdateInfoModel"] = UserUpdateInfoModelArray;
    }
    if (this.userUpdateModel["UseUpdateInfoModel"].length === 0) {
      document.getElementById("responseText").innerText = "There is no pricing change to publish";
    }
    this.pricechangeService.updatePriceChangeDetails(this.userUpdateModel).subscribe(res => {
      if (!(res.responseMessage === "") && res.responseMessage === "Success") { alert("Pricing menu published successfully") }
      else document.getElementById("responseText").innerHTML = "There is some issue with publishing, please try again";
    },
      err => {
        if (err.status === 401) this.errorMessage = true;
      },
    );
  }

  revertToRRP(event) {
      for (const [key, value] of Object.entries(this.restuarantNameProductMap)) {
        if (this.restaurantName.indexOf(value["RestaurantName"]) > -1) {
          for (let prod of value["Product"]) {
            if (this.SelectedCategory === "") this.SelectedCategory = "all";
            if (prod["CategoryName"] === this.SelectedCategory || this.SelectedCategory === "all") {
              prod.SixInchPrice = prod.SixInchRRPPrice;
              prod.FootLongPrice = prod.FootLongRRPPrice;
              prod.StandardPrice = prod.StandardRRPPrice;
              prod.IsSixInchPriceChanged = true;
              prod.IsFootLongPriceChanged = true;
              prod.IsStandardPriceChanged = true;
              prod.IsPriceChanged = true;
            }
          }
        }
    }
    event.stopPropagation();
    return false;
  }

  changeTheProductPrice(event, priceType, productId) {
    for (const [key, value] of Object.entries(this.restuarantNameProductMap)) {
      for (let prod of value["Product"]) {
        if (productId === prod["ProductId"] && priceType === "SixInchPrice" && !(prod["SixInchPrice"] === event.target.value)) {
          prod.SixInchPrice = event.target.value;
          prod.IsSixInchPriceChanged = true;
          prod.IsPriceChanged = true;
          break;
        } else if (productId === prod["ProductId"] && priceType === "FootLongPrice" && !(prod["FootLongPrice"] === event.target.value)) {
          prod.FootLongPrice = event.target.value;
          prod.IsFootLongPriceChanged = true;
          prod.IsPriceChanged = true;
          break;
        } else if (productId === prod["ProductId"] && priceType === "StandardPrice" && !(prod["StandardPrice"] === event.target.value)) {
          prod.StandardPrice = event.target.value;
          prod.IsStandardPriceChanged = true;
          prod.IsPriceChanged = true;
          break;
        }
      }
    }
  }

  selectCategory(event) {
    this.SelectedCategory = event.target.value;
    let ischecked = event.target.checked;
    for (const [key, value] of Object.entries(this.restuarantNameProductMap)) {
      console.log(value["Product"]);
      if (this.restaurantName.indexOf(value["RestaurantName"]) > -1) {
        for (let prod of value["Product"]) {
          if (this.SelectedCategory === "all") {
            prod["hidetable"] = false;
          }
          else if ((this.SelectedCategory === prod.CategoryName) && ischecked) {
            prod["hidetable"] = false;
          }
          else if (!(this.SelectedCategory === prod.CategoryName) && prod["hideTable"] === undefined) {
            prod["hidetable"] = true;
          }
        }
      }
    }
  }
  onClickOfPriceChangeBtn(event) {
    event.stopPropagation();
  }
}
