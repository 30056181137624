import { Component, OnInit } from '@angular/core';
import { AuthNewService } from '../shared/services/auth.service';
import { AUTH_CONFIG } from '../auth/auth2.config';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  private user: any;
    public userName: any;
    private isSingapore = false;
    private isANZ = false;
  constructor(public auth: AuthNewService/*, public jwt: JwtService*/) { }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem("user"));
      this.userName = this.user["name"];
      this.user.Country = this.user[AUTH_CONFIG.AUDIENCE+"/Country"];
      if (this.user.Country.includes('SG'))
          this.isSingapore = true;

      if (this.user.Country.includes('AU') || this.user.Country.includes('NZ')  )
          this.isANZ = true;
  }
}
