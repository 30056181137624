import { BrowserModule } from '@angular/platform-browser';
import { JwtModule } from "@auth0/angular-jwt";
import { NgModule, APP_INITIALIZER, Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { Observable } from 'rxjs';
import { RouterModule } from '@angular/router'
import { HttpClientModule } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import * as $ from 'jquery';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { MenuComponent } from './menu/menu.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { EnvironmentUrlService } from './shared/services/environment-url.service';
import { RepositoryService } from './shared/services/repository.service';
import { InternalServerComponent } from './error-pages/internal-server/internal-server.component';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { CallbackComponent } from './callback/callback.component';
import { ProfileComponent } from './profile/profile.component';
import { AuthNewService } from './shared/services/auth.service';
//import { AuthGuard } from './shared/auth-guard/auth.guard';
import { RegistrationComponent } from './registration/registration.component';
import { ContactusComponent } from './contactus/contactus.component';
import { RegistrationService } from './registration/registration.service';
import { SubsComponent } from './subs/subs.component';
import { NonsubsComponent } from './nonsubs/nonsubs.component';
import { MessageComponent } from './message/message.component';
//import { AppLoadService } from './shared/services/apploader.service';
import { JwtService } from './shared/services/jwt.service';
import { BreakfastsubsComponent } from './breakfastsubs/breakfastsubs.component';
import { SaladComponent } from './salad/salad.component';
import { LoginComponent } from './login/login.component';


//export function init_app(appLoadService: AppLoadService) {
//  return () => appLoadService.initializeApp();
//}

//export function get_settings(appLoadService: AppLoadService) {
//  return () => appLoadService.getSettings();
//}

// called on every request to retrieve the token
//export function tokenGetter() {
//  var userObject = JSON.parse(sessionStorage.getItem("user"));
//  return userObject.Token;
 
//}


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    NotFoundComponent,
    InternalServerComponent,
    CallbackComponent,
    ProfileComponent,
    RegistrationComponent,
    ContactusComponent,
    NonsubsComponent,
    SubsComponent,
    MessageComponent,
    BreakfastsubsComponent,
        SaladComponent,
        LoginComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        //tokenGetter: tokenGetter,
        //whitelistedDomains: ["dmbpricingapi"],
        //blacklistedRoutes: ["example.com/examplebadroute/"]
      }
    }),
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    RouterModule.forRoot(
      [
      { path: 'callback', component: CallbackComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'home', component: HomeComponent },
      { path: 'registration', component: RegistrationComponent },
            { path: 'contactus', component: ContactusComponent },
            { path: 'login', component: LoginComponent },
      //temporary profile subs,nonsubs,mops pages -->starts
        { path: 'pricechange/subs', component: SubsComponent },
        { path: 'pricechange/nonsubs', component: NonsubsComponent },
        { path: 'pricechange/breakfastsubs', component: BreakfastsubsComponent },
        { path: 'pricechange/salads', component: SaladComponent },
      //temporary profile subs,nonsubs,mops pages -->ends

      { path: 'product', loadChildren: "./product/product.module#ProductModule" },
      { path: 'user', loadChildren: "./user/user.module#UserModule", data: {userid:''} },
      //{ path: '404', component: NotFoundComponent },
      //{path: '500', component: InternalServerComponent},
            { path: '', redirectTo: '/login', pathMatch: 'full' },
            { path: '**', redirectTo: '/404', pathMatch: 'full' },
      //{ path: '', component: AppComponent }
      ])
  ],
  providers: [
    EnvironmentUrlService,
    RepositoryService,
    AuthNewService,
    ErrorHandlerService,
    RegistrationService,
    JwtService,
      //{ provide: APP_INITIALIZER, useFactory: init_app, deps: [AppLoadService], multi: true },
    
   //AppLoadService,
   //   { provide: APP_INITIALIZER, useFactory: init_app, deps: [AppLoadService], multi: true },
   //   { provide: APP_INITIALIZER, useFactory: get_settings, deps: [AppLoadService], multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
