import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UserModel } from 'src/app/_interfaces/UserModel';
import { UserUpdateModel } from 'src/app/_interfaces/UserUpdateModel';
import { CONFIG } from '../../app.config';
import { AUTH_CONFIG } from '../../auth/auth2.config';

@Injectable({
  providedIn: 'root'
})
export class PricechangeService {
  private userId: any;
  private user: any;
  private baseUrl: string = CONFIG.apiURL;

  constructor(private http: HttpClient) {

      this.user = JSON.parse(sessionStorage.getItem("user"));
      if (this.user != null) {
          //this.userId = this.user.ContegroId;
          this.userId = this.user[AUTH_CONFIG.AUDIENCE + "/Email"];

      }
  }
  getSubDetails() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
      })
    };
    return this.http.get('https://' + this.baseUrl + '/api/restaurant/getallsubsperuser/' + this.userId, httpOptions)
      .pipe(map(res => <UserModel[]>res));
  }
  getNonSubDetails() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
      })
    };
    return this.http.get('https://' + this.baseUrl + '/api/restaurant/getallnonsubsperuser/' + this.userId, httpOptions)
      .pipe(map(res => <UserModel[]>res));
    //api / restaurant / getallnonsubsperuser / { userId }
  }
  getSaladDetails() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
      })
    };
    return this.http.get('https://' + this.baseUrl + '/api/restaurant/getallsaladsperuser/' + this.userId, httpOptions)
      .pipe(map(res => <UserModel[]>res));
    //api / restaurant / getallnonsubsperuser / { userId }
  }
  getBreakfastDetails() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
      })
    };
    return this.http.get('https://' + this.baseUrl + '/api/restaurant/getallbreakfastperusersg/' + this.userId, httpOptions)
      .pipe(map(res => <UserModel[]>res));
    //api / restaurant / getallnonsubsperuser / { userId }
  }

  updatePriceChangeDetails(userUpdateModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
      })
    };
    return this.http.post<UserUpdateModel>('https://' + this.baseUrl + '/api/productrestaurantrelationship/updateall', userUpdateModel, httpOptions)
      .pipe(map((res: any) => res));
  }
}
