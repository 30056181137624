import { Component, OnInit } from '@angular/core';
import { AuthNewService } from '../shared/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {
    constructor(public auth: AuthNewService, private router: Router) {
      

    }

    ngOnInit() { }



}

