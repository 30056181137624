import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RestaurantModel } from '../_interfaces/RestaurantModel';
import { map } from 'rxjs/operators';
import { CONFIG } from '../app.config';


@Injectable({
  providedIn: 'root'
})


export class RegistrationService {
  private userId: any;
  private baseUrl: string = CONFIG.apiURL;
  private user: any;
 
  constructor(private http: HttpClient) {

  
    this.user = JSON.parse(sessionStorage.getItem("user"));
    if (this.user != null) {
      //this.userId = this.user.ContegroId;
      this.userId = this.user.hasOwnProperty("https://dmbpricing.com/Email") ? this.user["https://dmbpricing.com/Email"] : this.user["https://dmbpricingapi-test.azurewebsites.net/Email"]

    }

   
  }
  createAuthorizationHeader(headers: HttpHeaders) {
    headers.append('Authorization', 'Basic ' +
      sessionStorage.getItem("access_token") );
  }

  getRestaurantNames() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
      })
    };
    return this.http.get<RestaurantModel[]>("https://" + this.baseUrl + "/api/restaurant/getallrestaurantsperuser/" + this.userId, httpOptions)
      .pipe(map(res => <RestaurantModel[]>res));
  }

  getRestaurantModel() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
      })
    };
    return this.http.get('https://' + this.baseUrl + '/api/restaurant/getallproperties', httpOptions)
      .pipe(map((res: any) => <RestaurantModel[]>res));
  }

  submitRegistrationDetails(restaurantModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
      })
    };
    return this.http.post<RestaurantModel>('https://'+this.baseUrl+'/api/restaurant/updaterestaurant', restaurantModel, httpOptions)
    .pipe(map((res: any) => res));
  }


}

