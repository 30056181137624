import { Component, OnInit } from '@angular/core';
import { JwtService } from '../shared/services/jwt.service';
import { AuthNewService } from '../shared/services/auth.service';
import { AUTH_CONFIG } from '../auth/auth2.config';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  homeText: string;
 
  private user: any;
    private userId: any;
  
    private isSingapore = false;
    private isSGMY = false;
    private isANZ = false;
    private isMalaysia = false;

    constructor(private jwt: JwtService, public auth: AuthNewService) {

    }
  ngOnInit() {
    this.homeText = "Creating your Restaurant profile";

      this.user = JSON.parse(sessionStorage.getItem("user"));
      if (this.user != null) {
          // this.userId = this.user.ContegroId;
          this.userId = this.user[AUTH_CONFIG.AUDIENCE + "/Email"];
          this.user.Country = this.user[AUTH_CONFIG.AUDIENCE + "/Country"];

          if (this.user.Country != null) { }

          if (this.user.Country.toUpperCase().includes('SG'))
              this.isSingapore = true;
          if (this.user.Country.toUpperCase().includes('MY'))
              this.isMalaysia = true;
          if (this.user.Country.toUpperCase().includes('SG') || this.user.Country.toUpperCase().includes('MY'))
              this.isSGMY = true;
          if (this.user.Country.toUpperCase().includes('AU') || this.user.Country.toUpperCase().includes('NZ'))
              this.isANZ = true;
  }
    }


 

}
