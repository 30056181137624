import { Component, OnInit } from '@angular/core';

import { AuthNewService } from '../shared/services/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    constructor(public auth: AuthNewService) { }
    private loginBoolean: boolean;
    
    private userdataSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    ngOnInit() {
        //this.getUserDetails(this.auth.loggedIn$).subscribe(loginBoolean => console.log(loginBoolean));
        this.loginBoolean = this.auth.loggedIn;
        if (!this.loginBoolean)
            this.auth.login();
    }
    
    //loginUser() {
    //    this.authService.login("/subcard/inprogress");
    //    //logged in. you can get the user profile like this:
    //    const user = this.authService.userProfile$;
    //    console.log(user);

    //}
    getUserDetails(userdataSubject): Observable<boolean> {
        return this.userdataSubject.asObservable();
    }

  
}
