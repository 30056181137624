import { Component, OnInit } from '@angular/core';
import { AUTH_CONFIG } from '../auth/auth2.config';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

    private user: any;
    private isSingapore = false;
    private isMalaysia = false;
    private isANZ = false;
    constructor() {
    this.user = JSON.parse(sessionStorage.getItem("user"));
       
        //if (this.user.Country.includes('SG'))
        //    this.isSingapore = true;

        //if (this.user.Country.includes('AU') || this.user.Country.includes('NZ'))
        //    this.isANZ = true;

        if (this.user != null) {
            // this.userId = this.user.ContegroId;

            this.user.Country = this.user[AUTH_CONFIG.AUDIENCE + "/Country"];

            if (this.user.Country != null) {
                if (this.user.Country.toUpperCase().includes('SG'))
                    this.isSingapore = true;
                if (this.user.Country.toUpperCase().includes('MY'))
                    this.isMalaysia = true;
                if (this.user.Country.toUpperCase().includes('AU') || this.user.Country.toUpperCase().includes('NZ'))
                    this.isANZ = true;
            }
        }
        }

  ngOnInit() {
  }

}
