// src/app/auth/auth.config.ts
//import { ENV } from '../env.config';

interface AuthConfig {
    CLIENT_ID: string;
    CLIENT_DOMAIN: string;
    AUDIENCE: string;
    REDIRECT: string;
    SCOPE: string;
};

export const AUTH_CONFIG: AuthConfig = {
    CLIENT_ID: 'mIQy23lRHNbQC6lbb839zkvrZn8K9CPb',
    CLIENT_DOMAIN: 'ipca.au.auth0.com', // e.g., you.auth0.com
    AUDIENCE: 'https://dmbpricing.com',
    REDIRECT: `https://dmbpricing.com`,
    SCOPE: 'openid profile',

};
