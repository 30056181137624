import { Component, OnInit } from '@angular/core';
import { RegistrationService } from './registration.service';
import { Restaurant } from '../_interfaces/restaurant.model';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  private user: any = JSON.parse(sessionStorage.getItem("user"));
  public userEmailAddress: any;
  
  restaurantModel = {};
  restaurantLoopModel = {};
  restaurantDetailsModel = {};
  selectedRestaurant: string = "";
  submitted = false;
  PostMixSelected = false;
  TPDSelected = false;
  POSModelOtherSelected = false;
  AudioSystemOtherSelected = false;
  DMBScreensOtherSelected = false;
  MondaySelected = false;
  TuesdaySelected = false;
  WednesdaySelected = false;
  ThursdaySelected = false;
  FridaySelected = false;
  SaturdaySelected = false;
  SundaySelected = false;
  responseObject: Restaurant;
  DaysTrading = [];
  DaysTradingHours = ["7:00 AM", "7:30 AM", "8:00 AM", "8:30 AM", "9:00 AM", "9:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM", "12:00 PM", "12:30 PM", "1:00 PM", "1:30 PM", "2:00 PM", "2:30 PM", "3:00 PM", "3:30 PM", "4:00 PM", "4:30 PM", "5:00 PM", "5:30 PM", "6:00 PM", "6:30 PM", "7:00 PM", "7:30 PM", "8:00 PM", "8:30 PM", "9:00 PM", "9:30 PM", "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM", "12:00 AM", "12:30 AM", "1:00 AM", "1:30 AM", "2:00 AM", "2:30 AM", "3:00 AM", "3:30 AM", "4:00 AM", "4:30 AM", "5:00 AM", "5:30 AM", "6:00 AM", "6:30 AM"];
  restuarantMap: any = [];
  Id = "";
  showWindowType = false;
  largeWindowsSelected = false;
  mediumWindowsSelected = false;
  smallWindowsSelected = false;
  responseText = "";
  isResponseTextNeeded = false;
  isResetDone = false;
  disableIfAlreadyRegistered = false;
  isTheCorrectModel = false;
  itemPosition = "";
  isRegistered = false;
  errorMessage = false;
  isSingapore = false;
  isMalaysia = false;
  isAustralia = false;
  isNewzealand = false;
  constructor(private registrationService: RegistrationService) { }

  ngOnInit() {
    if (this.user != null) {
      this.userEmailAddress = this.user.hasOwnProperty("https://dmbpricing.com/Email") ? this.user["https://dmbpricing.com/Email"] : this.user["https://dmbpricingapi-test.azurewebsites.net/Email"]
    }
    this.getRestaurantNames();
   
    this.getRestaurantModel();
    console.log(this.restaurantLoopModel);
  }

  public customTrackBy(index: number, obj: any): any {
    return index;
  }

  public getRestaurantNames() {
    this.registrationService.getRestaurantNames().subscribe(
      data => {
        this.restaurantModel = data;
        this.restuarantMap = data.map(({ ServicenowRestaurantId, RestaurantId, RestaurantName, IsRegistered }) => ({ ServicenowRestaurantId, RestaurantId, RestaurantName, IsRegistered }));
      },
      err => {
        if (err.status === 401) this.errorMessage = true;
      },
    );
  }

  public getRestaurantModel() {
    this.registrationService.getRestaurantModel().subscribe(
      data => { this.restaurantLoopModel = data },
      err => {
        if (err.status === 401) this.errorMessage = true;
      },
    );
  }

  submitRegistrationDetails() {
    this.submitted = true;
    for (const [key, value] of Object.entries(this.restuarantMap)) {
      if (this.selectedRestaurant.indexOf(value["RestaurantName"]) > 0) {
        this.restaurantDetailsModel["ServicenowRestaurantId"] = value['ServicenowRestaurantId'];
        this.restaurantDetailsModel["RestaurantId"] = value['RestaurantId'];
      }
    }
    this.restaurantDetailsModel["UpdatedBy"] = this.userEmailAddress;
    var uniqueNames = [];
    $.each(this.DaysTrading, function (i, el) {
      if ($.inArray(el, uniqueNames) === -1) uniqueNames.push(el);
    });
    this.restaurantDetailsModel["DaysTrading"] = uniqueNames.join();
    this.restaurantDetailsModel["PostalAddress"] = this.restaurantDetailsModel['PostalAddress'] + ',' + this.restaurantDetailsModel['PostalSuburb'] + ',' + this.restaurantDetailsModel['PostalPostCode'] + ',' + this.restaurantDetailsModel['PostalState'];
    this.registrationService.submitRegistrationDetails(this.restaurantDetailsModel).subscribe(res => {
      this.isResponseTextNeeded = true;
      this.responseText = (!(res.responseMessage === "") && res.responseMessage === "Success") ? "Restaurant registered successfully" : "There is some issue with the registration";
      this.disableIfAlreadyRegistered = true;
      this.responseObject = res.responseObject;
      this.restaurantDetailsModel["IsRegistered"] = true;
      this.isRegistered = true;
      this.restaurantDetailsModel = this.responseObject;
      setTimeout(function () {
        //window.location.reload();
        //this.route.navigate(["/registration"]);
      }, 4000);
    },
      err => {
      this.isResponseTextNeeded = true;
        this.responseText = (!(err.message === "")) ? "There is some issue with the registration, please try again." : ""
        if (err.status === 401) this.errorMessage = true;
      },
    );
  }

  dataChanged(item) {
      this.restaurantDetailsModel[item.target.name] = item.target.value;
  }

  onChangeOfRestaurantList(restaurantName) {
    this.selectedRestaurant = restaurantName;
    this.isResponseTextNeeded = false;
    this.isResetDone = true;
    for (const [key, value] of Object.entries(this.restuarantMap)) {
      if (restaurantName.indexOf(value["RestaurantName"]) > -1 && value['IsRegistered']) {
        this.disableIfAlreadyRegistered = true;
        
      } else if (restaurantName.indexOf(value["RestaurantName"]) > -1 && value['IsRegistered'] === false) {
        this.disableIfAlreadyRegistered = false;
      }
    }
    for (var key in this.restaurantModel) {
      if (this.restaurantModel.hasOwnProperty(key)) {
        for (var item in this.restaurantModel[key]) {
          if (this.restaurantModel[key][item] === restaurantName) {
            this.isTheCorrectModel = true;
            this.itemPosition = key;
          }
          if (item === "DMBScreensOther" && !(this.restaurantModel[key][item] === null)) this.DMBScreensOtherSelected = true;
          if (item === "POSModelOther" && !(this.restaurantModel[key][item] === null)) this.POSModelOtherSelected = true;
          if (item === "AudioSystemOther" && !(this.restaurantModel[key][item] === null)) this.AudioSystemOtherSelected = true;
          if (item == "SmallCupDrinksCombo" && !(this.restaurantModel[key][item] === null)) this.PostMixSelected = true;
        }
      }
    }

    if (this.isTheCorrectModel) {
      let daysTradingArray = [];
      this.MondaySelected = false;
      this.TuesdaySelected = false;
      this.WednesdaySelected = false;
      this.ThursdaySelected = false;
      this.FridaySelected = false;
      this.SaturdaySelected = false;
      this.SundaySelected = false;
      this.restaurantDetailsModel = this.restaurantModel[this.itemPosition];
      if (this.restaurantDetailsModel["IsRegistered"])
        this.isRegistered = true;
      else this.isRegistered = false;

        if (this.restaurantDetailsModel["Country"] == "AU") {
            this.isAustralia = true;
        }
        else if (this.restaurantDetailsModel["Country"] == "NZ") {
            this.isNewzealand = true;
        }
        else if (this.restaurantDetailsModel["Country"] == "SG") {
            this.isSingapore = true;
        }
        else if (this.restaurantDetailsModel["Country"] == "MY") {
            this.isMalaysia = true;
        }

      this.responseObject = <Restaurant>this.restaurantDetailsModel;
      for (var key in this.responseObject) {
        if (this.responseObject.hasOwnProperty(key)) {
          if (typeof (this.responseObject[key]) == "string") { 
            if (this.responseObject[key].indexOf(':') > -1 && !(this.responseObject[key].indexOf(' AM') > -1 || this.responseObject[key].indexOf(' PM') > -1)) {
            var str = this.responseObject[key];
            this.responseObject[key] = str.substring(str.indexOf(":") + 1, str.length);
            }
          }
        }
      }
      if (!(this.restaurantDetailsModel["DaysTrading"] == null)) {
        daysTradingArray = this.restaurantDetailsModel["DaysTrading"].split(",");
      }
      var daysString = daysTradingArray.join();
      if (daysString.indexOf("Monday") > -1) {
          this.MondaySelected = true;
          document.getElementById("Monday").setAttribute("checked", "true");
        }
      if (daysString.indexOf("Tuesday") > -1) {
          this.TuesdaySelected = true;
          document.getElementById("Tuesday").setAttribute("checked","true");
        }
      if (daysString.indexOf("Wednesday") > -1) {
          this.WednesdaySelected = true;
          document.getElementById("Wednesday").setAttribute("checked", "true");
        }
      if (daysString.indexOf("Thursday") > -1) {
          this.ThursdaySelected = true;
          document.getElementById("Thursday").setAttribute("checked", "true");
        }
      if (daysString.indexOf("Friday") > -1) {
          this.FridaySelected = true;
          document.getElementById("Friday").setAttribute("checked", "true");
        }
      if (daysString.indexOf("Saturday") > -1) {
          this.SaturdaySelected = true;
          document.getElementById("Saturday").setAttribute("checked", "true");
        }
      if (daysString.indexOf("Sunday") > -1) {
          this.SundaySelected = true;
          document.getElementById("Sunday").setAttribute("checked", "true");
        }
      
    }
  }

  onClickOfRegisterBtn(event) { // if another restaurant selects from dropdown, reset the form and on submit of registration, remove the reset, otherwise error of fields will not be shown
    this.isResetDone = false;
    event.stopPropagation();
    
  }

  onClickOfCancelBtn() {
    this.isResetDone = true;
    if (this.user != null) {
      this.userEmailAddress = this.user["EmailAddress"];
    }
  }

  isWindowRequired(event) {
    this.restaurantDetailsModel[event.target.name] = event.target.value;
    if (event.target.value.includes("Yes")) this.showWindowType = true;
    else this.showWindowType = false;
  }

  selectSizeOfWindows(event) {
    this.restaurantDetailsModel[event.target.name] = event.target.value;
    let size = event.target.value;
    let isSizeChecked = event.target.checked;
    if(isSizeChecked && size === "Small") {
      this.smallWindowsSelected = true;
    }
    if (!isSizeChecked && size === "Small") {
      this.smallWindowsSelected = false;
    }
    if (isSizeChecked && size === "Medium") {
      this.mediumWindowsSelected = true;
    }
    if (!isSizeChecked && size === "Medium") {
      this.mediumWindowsSelected = false;
    }
    if(isSizeChecked && size === "Large") {
      this.largeWindowsSelected = true;
    }
    if (!isSizeChecked && size === "Large") {
      this.largeWindowsSelected = false;
    }
  }

  onSelectPostMixOption(event) {
    this.restaurantDetailsModel[event.target.name] = event.target.value;
    let postmix = event.target.value;
    if(postmix.includes("Post Mix") && this.restaurantDetailsModel["Country"] == "NZ") {
      this.PostMixSelected = true;
    }
    else {
      this.PostMixSelected = false;
    }
  }

  AddAddress(checked, RestaurantAddress, Suburb, PostCode, State, Area) {
    if (checked) {
      this.restaurantDetailsModel['PostalAddress'] = RestaurantAddress.value;
      this.restaurantDetailsModel['PostalSuburb'] = Suburb.value;
      this.restaurantDetailsModel['PostalPostCode'] = PostCode.value;
      this.restaurantDetailsModel['PostalState'] = State.value;
    }
  }

  onSelectThirdPartyDeliveryOption(event) {
    this.restaurantDetailsModel[event.target.name] = event.target.value;
    let tpd = event.target.value;
    if (tpd.includes("Yes")) {
      this.TPDSelected = true;
    }
    else {
      this.TPDSelected = false;
    }
  }

  onSelectOfOtherOption(event, select) {
    this.restaurantDetailsModel[event.target.name] = event.target.value;
    let other = event.target.value;
    if (other.includes("Other")) {
      if (select === "POSModelOther") this.POSModelOtherSelected = true;
      if (select === "AudioSystemOther") this.AudioSystemOtherSelected = true;
      if (select === "DMBScreensOther") this.DMBScreensOtherSelected = true;
    }
    else {
      if (select === "POSModelOther") this.POSModelOtherSelected = false;
      if (select === "AudioSystemOther") this.AudioSystemOtherSelected = false;
      if (select === "DMBScreensOther") this.DMBScreensOtherSelected = false;
    }
  }

  selectDay(event) {
    let selectedDay = event.target.value;
    let isdayChecked = event.target.checked;
    this.restaurantDetailsModel[event.target.name] = event.target.value;
    if (isdayChecked && selectedDay == "Monday") {
      this.MondaySelected = true;
      this.DaysTrading.push(selectedDay);
    }
    if (!isdayChecked && selectedDay == "Monday") {
      this.MondaySelected = false;
      this.DaysTrading.splice(this.DaysTrading.indexOf('Monday'), 1);
    }
    if (isdayChecked && selectedDay == "Tuesday") {
      this.TuesdaySelected = true;
      this.DaysTrading.push(selectedDay);
    }
    if (!isdayChecked && selectedDay == "Tuesday") {
      this.TuesdaySelected = false;
      this.DaysTrading.splice(this.DaysTrading.indexOf('Tuesday'), 1);
    }
    if (isdayChecked && selectedDay == "Wednesday") {
      this.WednesdaySelected = true;
      this.DaysTrading.push(selectedDay);
    }
    if (!isdayChecked && selectedDay == "Wednesday") {
      this.WednesdaySelected = false;
      this.DaysTrading.splice(this.DaysTrading.indexOf('Wednesday'), 1);
    }
    if (isdayChecked && selectedDay == "Thursday") {
      this.ThursdaySelected = true;
      this.DaysTrading.push(selectedDay);
    }
    if (!isdayChecked && selectedDay == "Thursday") {
      this.ThursdaySelected = false;
      this.DaysTrading.splice(this.DaysTrading.indexOf('Thursday'), 1);
    }
    if (isdayChecked && selectedDay == "Friday") {
      this.FridaySelected = true;
      this.DaysTrading.push(selectedDay);
    }
    if (!isdayChecked && selectedDay == "Friday") {
      this.FridaySelected = false;
      this.DaysTrading.splice(this.DaysTrading.indexOf('Friday'), 1);
    }
    if (isdayChecked && selectedDay == "Saturday") {
      this.SaturdaySelected = true;
      this.DaysTrading.push(selectedDay);
    }
    if (!isdayChecked && selectedDay == "Saturday") {
      this.SaturdaySelected = false;
      this.DaysTrading.splice(this.DaysTrading.indexOf('Saturday'), 1);
    }
    if (isdayChecked && selectedDay == "Sunday") {
      this.SundaySelected = true;
      this.DaysTrading.push(selectedDay);
    }
    if (!isdayChecked && selectedDay == "Sunday") {
      this.SundaySelected = false;
      this.DaysTrading.splice(this.DaysTrading.indexOf('Sunday'), 1);
    }
  }
}
