import { Component, OnInit } from '@angular/core';
import { PricechangeService } from '../shared/services/pricechange.service';
@Component({
  selector: 'app-breakfastsubs',
  templateUrl: './breakfastsubs.component.html',
  styleUrls: ['../subs/subs.component.css']
})
export class BreakfastsubsComponent implements OnInit {
  private user: any = JSON.parse(sessionStorage.getItem("user"));
  public userEmailAddress: any;
  dropdownList = [];
  dropdownSettings = {};
  heading = "Subs";
  restaurantNameList = [];
  restuarantNameProductMap = {};
  categories = [];
  products = [];
  hidetable = false;
  restaurantselected = false;
  restaurantName = "";
  checkedRowsOnCatSelection = [];
  SelectedCategory = "";
  userUpdateModel = {};
  multiItemSelected = false;
  PortalMessageList = [];
  isSingapore = false;
  hideLoader = false;
  isNZ = false;
  isAustralia = false;
  errorMessage = false;

  constructor(private pricechangeService: PricechangeService) { }

  ngOnInit() {
    this.heading = "Breakfast";
    this.getAllRestaurantProducts();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll: false
    };
  }
  onItemSelect(item: any) {
    for (const [key, value] of Object.entries(this.restuarantNameProductMap)) {
      if (value["RestaurantId"] === item["item_id"]) {
        for (let product of value["Product"]) {
          product["MultiItemSelected"] = true;
        }
      }
    }
  }
  OnItemDeSelect(item: any) {
    for (const [key, value] of Object.entries(this.restuarantNameProductMap)) {
      if (value["RestaurantId"] === item["item_id"]) {
        for (let product of value["Product"]) {
          product["MultiItemSelected"] = false;
        }
      }
    }
  }

  public getAllRestaurantProducts() {
    this.pricechangeService.getBreakfastDetails().subscribe(
      data => {
        this.restuarantNameProductMap = data["RestaurantProductModel"].map(({ RestaurantName, RestaurantId, Product, IsRegistered, Country }) => ({ RestaurantName, RestaurantId, Product, IsRegistered, Country }));
        (<Array<String>>this.restuarantNameProductMap).forEach((item, index) => {
          if (item["IsRegistered"]) {
            this.dropdownList.push({
              item_id: item["RestaurantId"],
              item_text: item["RestaurantName"]
            })
          }
          if (data["EmailAddress"] != null) {
            this.userEmailAddress = data["EmailAddress"];
          }
        });
        (<Array<String>>this.restuarantNameProductMap).forEach((item, index) => {
          this.restaurantNameList.push({
            RestaurantName: item["RestaurantName"],
            IsRegistered: item["IsRegistered"]
          })
        });
        for (const [key, value] of Object.entries(data["RestaurantProductModel"])) {
          if (value["Country"] === "NZ")
                this.isNZ = true;
          if (value["Country"] === "SG")
                this.isSingapore = true;
          if (value["Country"] === "AU")
                this.isAustralia = true;
          if (value["PricingPortalMessage"] != null) {
            this.PortalMessageList = value["PricingPortalMessage"].split(",,");
          }
        };
        this.hideLoader = true;
      },
      err => {
        if (err.status === 401) this.errorMessage = true;
      },
    );
  }

  public onChangeOfRestaurantList(restaurantName) {
    this.restaurantName = restaurantName;
    this.restaurantselected = true;
    for (const [key, value] of Object.entries(this.restuarantNameProductMap)) {
      if (restaurantName.indexOf(value["RestaurantName"]) > -1) {
        this.products = value["Product"];
      }
    }
    for (let product of this.products) {
      this.categories.push(product["CategoryName"]);
    }
    this.categories = this.categories.reduce(function (a, b) {
      if (a.indexOf(b) < 0) a.push(b);
      return a;
    }, []);
  }

  submitPriceChangeDetails() {
    let UserUpdateInfoModelArray = [];
    for (const [key, value] of Object.entries(this.restuarantNameProductMap)) {
      for (let prod of value["Product"]) {
        let updateProduct = {};
        if (prod["IsSixInchPriceChanged"]) {
          updateProduct["SixInchProductPrice"] = prod["SixInchPrice"];
          updateProduct["SixInchProductSizeSysId"] = prod["SixInchSizeId"];
        }
        if (prod["IsThreeInchFlatBreadPriceChanged"]) {
          updateProduct["ThreeInchFlatbreadProductPrice"] = prod["ThreeInchFlatbreadPrice"];
          updateProduct["ThreeInchFlatbreadProductSizeSysId"] = prod["ThreeInchFlatbreadSizeId"];
        }
        if (prod["IsSixInchFlatBreadPriceChanged"]) {
          updateProduct["SixInchFlatbreadProductPrice"] = prod["SixInchFlatbreadPrice"];
          updateProduct["SixInchFlatbreadProductSizeSysId"] = prod["SixInchFlatbreadSizeId"];
        }
        if (prod["IsStandardPriceChanged"]) {
          updateProduct["StandardProductPrice"] = prod["StandardPrice"];
          updateProduct["StandardProductSizeSysId"] = prod["StandardSizeId"];
        }
        updateProduct["productActive"] = "active";
        updateProduct["RestaurantSysId"] = value["RestaurantId"];
        updateProduct["UpdatedBy"] = this.userEmailAddress;
        if (this.restaurantName.indexOf(value["RestaurantName"]) > -1) {
          updateProduct["MultiItemSelected"] = true;
        } else {
          updateProduct["MultiItemSelected"] = prod["MultiItemSelected"];
        }
        if (!(prod["IsPriceChanged"] === undefined)) {
          UserUpdateInfoModelArray[UserUpdateInfoModelArray.length] = (updateProduct);
        }
      }
      this.userUpdateModel["UseUpdateInfoModel"] = UserUpdateInfoModelArray;
    }
    if (this.userUpdateModel["UseUpdateInfoModel"].length === 0) {
      document.getElementById("responseText").innerHTML = "There is no pricing change to publish"
    }
    this.pricechangeService.updatePriceChangeDetails(this.userUpdateModel).subscribe(res => {
      if (!(res.responseMessage === "") && res.responseMessage === "Success") { alert("Pricing menu published successfully") }
      else document.getElementById("responseText").innerHTML = "There is some issue with publishing, please try again";
    },
      err => {
        if (err.status === 401) this.errorMessage = true;
      },
    );
  }

  revertToRRP(event) {
      for (const [key, value] of Object.entries(this.restuarantNameProductMap)) {
        if (this.restaurantName.indexOf(value["RestaurantName"]) > -1) {
          for (let prod of value["Product"]) {
            if (this.SelectedCategory === "") this.SelectedCategory = "all";
            if (prod["CategoryName"] === this.SelectedCategory || this.SelectedCategory === "all") {
              prod.SixInchPrice = prod.SixInchRRPPrice;
              prod.ThreeInchFlatbreadPrice = prod.ThreeInchFlatbreadRRPPrice;
              prod.SixInchFlatbreadPrice = prod.SixInchFlatbreadRRPPrice;
              prod.StandardPrice = prod.StandardRRPPrice;
              prod.IsSixInchPriceChanged = true;
              prod.IsThreeInchFlatBreadPriceChanged = true;
              prod.IsSixInchFlatBreadPriceChanged = true;
              prod.IsStandardPriceChanged = true;
              prod.IsPriceChanged = true;
            }
          }
        }
      }
    event.stopPropagation();
    return false;
  }

  changeTheProductPrice(event, priceType, productId) {
    for (const [key, value] of Object.entries(this.restuarantNameProductMap)) {
        for (let prod of value["Product"]) {
          if (productId === prod["ProductId"] && priceType === "SixInchPrice" && !(prod["SixInchPrice"] === event.target.value)) {
            prod.SixInchPrice = event.target.value;
            prod.IsSixInchPriceChanged = true;
            prod.IsPriceChanged = true;
            break;
          } else if (productId === prod["ProductId"] && priceType === "ThreeInchFlatbreadPrice" && !(prod["ThreeInchFlatbreadPrice"] === event.target.value)) {
            prod.ThreeInchFlatbreadPrice = event.target.value;
            prod.IsThreeInchFlatBreadPriceChanged = true;
            prod.IsPriceChanged = true;
            break;
          } else if (productId === prod["ProductId"] && priceType === "SixInchFlatbreadPrice" && !(prod["SixInchFlatbreadPrice"] === event.target.value)) {
            prod.SixInchFlatbreadPrice = event.target.value;
            prod.IsSixInchFlatBreadPriceChanged = true;
            prod.IsPriceChanged = true;
            break;
          } else if (productId === prod["ProductId"] && priceType === "StandardPrice" && !(prod["StandardPrice"] === event.target.value)) {
            prod.StandardPrice = event.target.value;
            prod.IsStandardPriceChanged = true;
            prod.IsPriceChanged = true;
            break;
          }
        }
    }
  }

  selectCategory(event) {
    this.SelectedCategory = event.target.value;
    let ischecked = event.target.checked;
    for (const [key, value] of Object.entries(this.restuarantNameProductMap)) {
      console.log(value["Product"]);
      if (this.restaurantName.indexOf(value["RestaurantName"]) > -1) {
        for (let prod of value["Product"]) {
          if (this.SelectedCategory === "all") {
            prod["hidetable"] = false;
          }
          else if ((this.SelectedCategory === prod.CategoryName) && ischecked) {
            prod["hidetable"] = false;
          }
          else if (!(this.SelectedCategory === prod.CategoryName) && prod["hideTable"] === undefined) {
            prod["hidetable"] = true;
          }
        }
      }
    }
  }
  onClickOfPriceChangeBtn(event) {
    event.stopPropagation();
  }
}

