import { Component, OnInit } from '@angular/core';
import { AuthNewService } from './shared/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    constructor(public auth: AuthNewService, private router: Router) { }
  RestuarantName : string = "Restaurant1;"
  ngOnInit() {
     this.auth.handleAuth();
      //if (!this.auth.loggedIn)
      //this.auth.login();
  }
    
}
