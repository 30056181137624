import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CONFIG } from '../../app.config';

@Injectable({
  providedIn: 'root'
})

export class JwtService {
  private baseUrl: string = CONFIG.apiURL;

  constructor(private httpClient: HttpClient) {
   

    }
  public get loggedIn(): boolean {
    return sessionStorage.getItem('Token') !== null;
  }


  loginUser(userId) {
    return this.httpClient.post("https://"+ this.baseUrl +"/api/user/login/" + userId, {})
      .pipe(map(res => <any>res));
  }
  logout() {
    sessionStorage.removeItem('Token');
    sessionStorage.removeItem('user');
    window.location.href = "https://ipca.biz"
  }
}
